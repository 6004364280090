import { createSlice } from '@reduxjs/toolkit';
import assign from 'lodash/assign';

const userSlice = createSlice({
  name: 'user',
  initialState: { lang: 'en' },
  reducers: {
    login: (state, action) => {
      assign(state, action.payload);
      // Update global user data for digital data tracking
      if (typeof window !== 'undefined' && window.globalUserData) {
        window.globalUserData.partyId = action.payload.id;
        window.globalUserData.rootPartyId = action.payload.rootPartyId;
        if (window.updateDigitalData) {
          window.updateDigitalData();
        }
      }
    }
  }
});

export const { login } = userSlice.actions;

export const selectUser = (state) => state.user;

export default userSlice.reducer;
